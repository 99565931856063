<template>
  <div class="p-4 listview card border-light">
    <div class="row">
      <div v-if="!isMultiOffice" class="col-12 col-sm-6 col-md-3">
        <div class="cell">
          <label>{{ $t('company') }}: </label>
          <span>{{ value.companyName || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('localization') }}: </label>
          <span>{{ value.locationConfigurationName || '-' }}</span>
        </div>
      </div>
      <div
        class="col-12"
        :class="{
          'col-sm-6 col-md-3': !isMultiOffice,
          'col-sm-4': isMultiOffice,
        }"
      >
        <div class="cell">
          <label>{{ $t('stationName') }} ESP: </label>
          <span>{{ value.name || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('stationName') }} ENG: </label>
          <span>{{ value.nameEN || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('role') }}: </label>
          <span>{{ value.roleName || '-' }}</span>
        </div>
      </div>
      <div
        class="col-12"
        :class="{
          'col-sm-6 col-md-3': !isMultiOffice,
          'col-sm-4': isMultiOffice,
        }"
      >
        <div class="cell">
          <label>{{ $t('queue') }}: </label>
          <span>{{ value.serviceQueueName || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('available') }}: </label>
          <span>{{ $t(value.isAvailable ? 'yes' : 'no') }}</span>
        </div>
      </div>

      <div
        class="col-12"
        :class="{
          'col-sm-6 col-md-3': !isMultiOffice,
          'col-sm-4': isMultiOffice,
        }"
      >
        <action-button :actions="actions" :text="$t('actions')" />
      </div>
    </div>
  </div>
</template>
<script>
import ActionButton from '@/components/basics/buttons/ActionButton';
import IdentityPermission from '@/constants/IdentityPermission';

export default {
  name: 'StationSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => this.$router.push({name: 'StationEdit', params: {id: this.value.id}}),
        },
        {
          label: this.$t('delete'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          action: () => this.$emit('on-delete'),
          visible: () =>
            this.userHasPermissions(IdentityPermission.station.delete),
        },
      ],
    };
  },
  computed: {
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
  },
};
</script>
<style lang="scss" scoped>
.listview .cell {
  clear: left;
}
.listview .cell label {
  float: left;
  font-weight: bold;
}
.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
.card {
  word-break: break-word;
}
</style>
