<template>
  <Permissions
    :value="permissions"
    @go-to="$emit('go-to', $event)"
    @on-submit="onSubmit($event)"
    :disabled="!roleMatch"
    configured-color="#28a745"
    selected-color="#FF8105"
  >
  <div class="row">
    <div class="col-md-8">
       <filter-select
      v-model="roleId"
      fieldtext="name"
      fieldvalue="id"
      label=""
      :options="filters.roles"
    />
    </div>
    <div class="col-md-4">
      <button
        class="btn btn-primary"
        @click="updateRole()"
        :disabled="roleMatch"
      >
        {{ $t('update') }}
      </button>
    </div>
    <div class="col-md-12">
      <error
      v-if="!roleMatch"
      class="mt-n1"
      :message="$t('mustUpdateTheRoleToModifyPermissions')"
    />
    </div>
  </div>

  <template v-slot:legend>
     <span class="chip green">
        {{ $t('rolePermission') }}
      </span>
      <span class="chip yellow">
        {{ $t('addedPermission') }}
      </span>
  </template>

  </Permissions>
</template>

<script>
import Permissions from '@/components/Permissions';
import SystemRoles from '@/constants/SystemRoles';
import {
  getPermissionsList as _getStationPermissionsList,
  getRolePermissionsList as _getStationRolePermissionsList,
  addPermissions as _addPermissions,
  updateStationRole as _updateStationRole
  } from '@/services/StationService';
import Error from '@/components/basics/error/CustomError.vue';

export default {
  name: 'AssignPermission',
  components: {
    Permissions,
    Error
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      permissions: [],
      roleId: null,
      initializing: true,
      filters: {
        roles: [],
      },
    };
  },
  mounted() {
    this.filters.roles = [
      {
        id: SystemRoles.clientlist,
        name: this.$t('roleclientlist'),
      },
      {
        id: SystemRoles.clientuser,
        name: this.$t('roleclientuser'),
      },
    ];
    this.roleId = this.model.roleId;
    if (!this.roleId) this.initializing = false;
    this.loadStationPermissions();
  },
  computed: {
    roleMatch() {
      return this.model.roleId === this.roleId;
    }
  },
  watch: {
    roleId() {
      if (this.initializing) return this.initializing = false;
      if (this.roleMatch) this.loadStationPermissions();
      else this.loadRoleStationPermissions();
    },
  },
  methods: {
    loadStationPermissions() {
       _getStationPermissionsList({
         stationId: this.model.id,
         roleId: this.roleId,
       }).then((response) => {
        this.permissions = response.data.map(permission => ({...permission, disabled: !this.roleMatch}));
      });
    },
    loadRoleStationPermissions() {
       _getStationRolePermissionsList({
         stationId: this.model.id,
         roleId: this.roleId,
         lastRoleId: !this.roleMatch ? this.model.roleId : null
       }).then((response) => {
        this.permissions = response.data.map(permission => ({...permission, disabled: !this.roleMatch}));
      });
    },
    updateRole() {
      _updateStationRole({id: this.model.id, roleId: this.roleId})
        .then(() => {
         this.ShowSuccessSaveToast();
         this.model.roleId = this.roleId;
         this.permissions.forEach(permission => {
            permission.disabled = false;
          });
      })
    },
    onSubmit(finalize) {
      if (!this.roleMatch) return this.ShowToast('Error', this.$t('mustUpdateTheRoleToModifyPermissions'), 'error');
      const permissions = this.permissions.filter(x => x.selected).map(x => x.id);
      _addPermissions({id: this.model.id, permissions}).then(() => {

        this.ShowSuccessSaveToast();
        if (finalize) this.$router.push({ name: 'Stations' });
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.chip {
  padding: 10px;
  margin: 5px;
  border-radius: 20px;
  opacity: 1;
  &.green {
    background-color: #28a745;
    color: white !important;
  }
  &.yellow {
    background-color: #FF8105;
    color: white !important;
  }
}

</style>
