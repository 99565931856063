<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card p-4" style="border: white">
        <validation-observer ref="formRef" novalidate>
          <div class="row">
            <sub-header :title="$t('stationInformation')" />
            <div class="col-md-12"></div>
            <div v-if="!isMultiOffice" class="col-md-6">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="model.companyId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="text"
                  fieldvalue="value"
                  :label="$t('company')"
                  :options="filters.companies"
                />
              </validation-provider>
            </div>
            <div v-if="!isMultiOffice" class="col-md-6">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="model.locationConfigurationId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="name"
                  fieldvalue="id"
                  :label="$t('localization')"
                  :options="filters.locations"
                />
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider v-slot="{ errors }" rules="required|max:50">
                <base-input
                  v-model="model.name"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :label="`${$t('stationName')} ESP`"
                />
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider v-slot="{ errors }" rules="max:50">
                <base-input
                  v-model="model.nameEN"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :label="`${$t('stationName')} ENG`"
                />
              </validation-provider>
            </div>
            <div class="col-md-6">
              <filter-select
                v-model="model.servieQueueId"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('row')"
                :options="filters.queues"
              />
              <b-form-checkbox
                v-model="model.fixedServiceQueue"
                :disabled="!model.servieQueueId"
                switch
              >
                {{ $t('fixedRow') }}
              </b-form-checkbox>
            </div>
            <div v-if="isMultiOffice" class="col-md-6">
              <filter-select
                v-model="model.roleId"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('role')"
                :options="filters.roles"
              />
            </div>
            <div class="col-md-6" :class="{ 'pt-4': !isMultiOffice }">
              <b-form-checkbox
                v-model="model.enabled"
                switch
                :class="{ 'mt-2': !isMultiOffice }"
              >
                {{ $t('available') }}
              </b-form-checkbox>
            </div>
          </div>
        </validation-observer>
      </div>
    </div>
    <div class="col-md-12 my-5 text-right">
      <base-filled-button
        bg-color="#707070"
        class="mx-0 float-left"
        icon-class="fas fa-ban"
        :on-click="() => $router.push({ name: 'Stations' })"
        :text="$t('cancel')"
      />

      <button
        class="btn btn-outline-primary"
        variant="outline-primary"
        @click="onSubmit()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </button>

      <button
        v-if="!isMultiOffice"
        class="btn btn-primary ml-2"
        @click="onSubmit(true)"
      >
        <i class="far fa-arrow-alt-circle-right" /> {{ $t('saveContinue') }}
      </button>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput';
import SubHeader from '@/components/SubHeader';
import { mapGetters } from 'vuex';
import { modulesName } from '@/store';
import IdentityPermission from '@/constants/IdentityPermission';
import { getQueueList as _getQueueList } from '@/services/ServicesService';
import { updateStation as _updateStation } from '@/services/StationService';
import SystemRoles from '@/constants/SystemRoles';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import { getCompanies as _getCompanies } from '@/services/CompanyService';

export default {
  name: 'Edit',
  components: {
    BaseInput,
    SubHeader,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filters: {
        companies: [],
        locations: [],
        queues: [],
        roles: [],
      },
      initialized: false,
    };
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
  },
  watch: {
    'model.companyId'(value) {
      if (this.isMultiOffice) return;
      this.filters.locations = [];
      if (this.initialized) this.model.locationConfigurationId = null;
      if (!value) return;
      this.getLocations(value);
    },
    'model.locationConfigurationId'(value) {
      if (this.isMultiOffice) return;
      this.filters.services = [];
      if (this.initialized) this.model.servieQueueId = null;
      if (!value) return;
      this.loadServiceQueues(value);
    },
    'model.servieQueueId': function (newValue) {
      if (!newValue) {
        this.model.fixedServiceQueue = false;
      }
    },
  },
  async mounted() {
    this.filters.roles = [
      {
        id: SystemRoles.clientlist,
        name: this.$t('roleclientlist'),
      },
      {
        id: SystemRoles.clientuser,
        name: this.$t('roleclientuser'),
      },
    ];
    if (this.isMultiOffice) {
      await this.loadServiceQueues(
        Number(this.currentActiveLocation.locationConfigurationId),
      );
    } else await this.getCompanies();
    setTimeout(() => {
      this.initialized = true;
    }, 300);
  },
  methods: {
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.filters.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getLocations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.filters.locations = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async loadServiceQueues(locationConfigurationId) {
      await _getQueueList(locationConfigurationId).then((response) => {
        this.filters.queues = response.data;
      });
    },
    async onSubmit(next) {
      if (!(await this.$refs.formRef.validate())) return;
      _updateStation(this.model).then(() => {
        this.ShowSuccessSaveToast();
        if (next) this.$emit('go-to', 1);
      });
    },
  },
};
</script>
