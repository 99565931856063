<template>
  <div class="col-md-12">
    <validation-observer ref="formRef" novalidate>
      <div class="row">
        <div v-if="!isMultiOffice" class="col-md-12">
          <validation-provider v-slot="{ errors }" rules="required">
            <filter-select
              v-model="model.companyId"
              :clearable="false"
              :error="errors[0]"
              :error-msg="$t('mustSelectOne')"
              fieldtext="text"
              fieldvalue="value"
              :label="$t('company')"
              :options="companies"
            />
          </validation-provider>
        </div>
        <div v-if="!isMultiOffice" class="col-md-12">
          <validation-provider v-slot="{ errors }" rules="required">
            <filter-select
              v-model="model.locationConfigurationId"
              :clearable="false"
              :error="errors[0]"
              :error-msg="$t('mustSelectOne')"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('localization')"
              :options="filters.locations"
            />
          </validation-provider>
        </div>
        <div class="col-md-12">
          <validation-provider v-slot="{ errors }" rules="required|max:50">
            <base-input
              v-model="model.name"
              :error="errors[0]"
              :error-msg="$t('invalidField')"
              :label="`${$t('stationName')} ESP`"
            />
          </validation-provider>
        </div>
        <div class="col-md-12">
          <validation-provider v-slot="{ errors }" rules="max:50">
            <base-input
              v-model="model.nameEN"
              :error="errors[0]"
              :error-msg="$t('invalidField')"
              :label="`${$t('stationName')} ENG`"
            />
          </validation-provider>
        </div>
        <div class="col-md-12 text-right mt-3">
          <base-filled-button
            bg-color="#707070"
            class="mx-0 float-left"
            icon-class="fas fa-ban"
            :on-click="() => $emit('close')"
            :text="$t('cancel')"
          />

          <button
            class="btn btn-outline-primary"
            variant="outline-primary"
            @click="onSubmit()"
          >
            <i class="far fa-save" /> {{ $t('save') }}
          </button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import IdentityPermission from '@/constants/IdentityPermission';
import BaseInput from '@/components/BaseInput.vue';
import { modulesName } from '@/store';
import { mapGetters } from 'vuex';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import { addStation as _addStation } from '@/services/StationService';

export default {
  name: 'CreateForm',
  components: {
    BaseInput,
  },
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      model: {
        companyId: null,
        locationConfigurationId: null,
        name: null,
        nameEN: null,
      },
      filters: {
        locations: [],
      },
    };
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
  },
  watch: {
    'model.companyId'(value) {
      this.filters.locations = [];
      this.model.locationConfigurationId = null;
      if (!value) return;
      this.getLocations(value);
    },
  },
  methods: {
    async getLocations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.filters.locations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit() {
      if (!(await this.$refs.formRef.validate())) return;
      await _addStation({
        ...this.model,
        locationConfigurationId: this.isMultiOffice
          ? Number(this.currentActiveLocation.locationConfigurationId)
          : this.model.locationConfigurationId,
      }).then(() => {
        this.ShowSuccessSaveToast();
        this.$emit('submitted');
        this.$emit('close');
      });
    },
  },
};
</script>
