<template>
  <div class="mt-5 container-fluid">
    <step-wizard
      class="mb-3"
      :step="stepIndex"
      :tabs="tabs"
      @OnStepChange="onStepChange($event)"
    />
    <ul id="tab-controls" class="nav nav-pills" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: isActive(0) }"
          @click.prevent="onStepChange(0)"
        />
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: isActive(1) }"
          @click.prevent="onStepChange(1)"
        />
      </li>
    </ul>
    <div id="tab-container">
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(0) }"
        role="tabpanel"
      >
        <Edit :model="model" @go-to="onStepChange($event)" />
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive(1) }"
        role="tabpanel"
      >
        <AssignPermissions v-if="isActive(1)" :model="model" @go-to="onStepChange($event)" />
      </div>
    </div>
  </div>
</template>
<script>
import StepWizard from '@/components/StepWizard';
import Edit  from './Edit';
import AssignPermissions  from './AssignPermissions';
import IdentityPermission from '@/constants/IdentityPermission';
import { getStationById as _getStationById } from '@/services/StationService';
import { mapGetters } from 'vuex';
import { modulesName } from '@/store';


export default {
  name: 'StationMaster',
  components: {
    StepWizard,
    Edit,
    AssignPermissions,
  },
  data() {
    return {
      stepIndex: 0,
      loading: false,
      model: {
        id: null,
        companyId: null,
        locationConfigurationId: null,
        name: null,
        nameEN: null,
        servieQueueId: null,
        roleId: null,
        enabled: false
      }
    }
  },
  mounted() {
    _getStationById(this.$route.params.id).then(response => {
      this.model = response.data;
    })
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
    tabs() {
      if (this.isMultiOffice) {
        return [
          {
          name: this.$t('stationMaintenance'),
          icon: 'fas fa-plus',
          disabled: false,
        },
        ]
      }
      return [
        {
          name: this.$t('stationMaintenance'),
          icon: 'fas fa-plus',
          disabled: false,
        },
        {
          name: this.$t('assignPermission'),
          icon: 'far fa-lock',
          disabled: this.isMultiOffice,
        },
      ];
    },
  },
  watch: {
    currentActiveLocation() {
      this.$router.replace({ name: 'Stations' });
    },
  },
  methods: {
    isActive(step) {
      return this.stepIndex == step;
    },
    onStepChange(step) {
      this.stepIndex = step;
    },
  },

}
</script>
<style lang="scss" scoped>
::v-deep .nav,
.nav-pills {
  display: none;
}
.tab-pane {
  display: none;
}
.tab-pane.active.show {
  display: block;
}
</style>
