<template>
  <section>
    <content-header  v-if="showMaintenance" :title="$t('stationsMaintenance')" />
    <div v-if="showMaintenance" class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterStations')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getStations(1)"
        />
      </div>

      <separator class="my-4" :text="$t('listOfStations')" />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :text="$t('create')"
            :on-click="() => showCreateModal = true"
          />
        </div>
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(station, index) in stations.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="station"
            @on-delete="openDeleteModal(station)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="stations.count > 0"
        id="pgPager"
        class="my-4"
        :data="stations"
        :page-size="pageSize"
        @pagination-go-page="getStations($event)"
        @pagination-rows-per-page="getStations(pageIndex, $event)"
      />
    </div>
    <BigLoader v-if="loading" />
    <AlertMessage
      v-if="!showMaintenance && !loading"
      :message="$t('selectOfficeForTheMaintenance')"
      style="margin-top: 150px"
    />
    <custom-modal
      v-model="showCreateModal"
      size="md"
      :title="$t('createStation')"
    >
      <CreateForm
        v-if="showCreateModal"
        :companies="filters.companies"
        @close="showCreateModal = false"
        @submitted="getStations()"
      />
    </custom-modal>
    <DeleteModal
      v-model="showDeleteModal"
      error-message="stationNameIsNotCorrect"
      :item="selectedStation"
      label="stationName"
      name="name"
      @on-confirm="onDelete()"
    />
  </section>
</template>

<script>
import CustomPagination from '@/components/CustomPagination';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import CustomModal from '@/components/basics/modal/CustomModal';
import SearchResult from './components/SearchResult';
import FilterManager from '@/components/FilterManager';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import BigLoader from '@/components/BigLoader';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import IdentityPermission from '@/constants/IdentityPermission';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import Separator from '@/components/Separator';
import { modulesName } from '@/store';
import DeleteModal from '@/components/DeleteModal';
import AlertMessage from '@/components/basics/alerts/AlertMessage';
import CreateForm from './components/CreateForm.vue';
import {
  getStations as _getStations,
  deleteStation as _deleteStation,
} from '@/services/StationService';
import { mapGetters } from 'vuex';

export default {
  name: 'Stations',
  components: {
    CustomPagination,
    CustomNotFound,
    SearchResult,
    FilterManager,
    contentHeader,
    SubHeader,
    Separator,
    DeleteModal,
    AlertMessage,
    CreateForm,
    CustomModal,
    BigLoader
  },
  data() {
    return {
      filters: {
        locations: [],
        companies: [],
      },
      selectedFilters: {
        location: [],
        company: [],
        name: '',
      },
      stations: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      showDeleteModal: false,
      selectedStation: null,
      showMaintenance: false,
      showCreateModal: false,
      loading: false
    };
  },
  mounted() {
    if (!this.isMultiOffice) {
      this.getCompanies();
      this.showMaintenance = true;
    } else if (this.isMultiOffice && this.currentActiveLocation) {
      this.showMaintenance = true;
    }
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
    filtersConfig() {
      if (!this.isMultiOffice) {
        return [
          {
            rules: '',
            name: 'company',
            label: this.$t('company'),
            component: 'FilterMultiSelect',
            options: this.filters.companies,
            placeholder: `-- ${this.$t('all')} --`,
            fieldtext: 'text',
            fieldvalue: 'value',
            clearable: true,
            class: 'col-sm-12 col-md-4 col-lg-3',
            error: this.$t('mustselectcompany'),
            initialValue: this.selectedFilters.company,
            onChanged: this.onCompanyChanged,
          },
          {
            rules: '',
            name: 'location',
            label: this.$t('localization'),
            component: 'FilterMultiSelect',
            options: this.filters.locations,
            placeholder: `-- ${this.$t('all')} --`,
            fieldtext: 'name',
            fieldvalue: 'id',
            clearable: true,
            class: 'col-sm-12 col-md-4 col-lg-3',
            initialValue: this.selectedFilters.location,
            onChanged: this.onLocationChanged,
          },
          {
            rules: '',
            name: 'name',
            label: this.$t('name'),
            component: 'BaseInput',
            placeholder: this.$t('name'),
            fieldtext: 'name',
            fieldvalue: 'id',
            class: 'col-sm-12 col-md-4 col-lg-3',
            initialValue: this.selectedFilters.name,
            value: this.selectedFilters.name,
          },
        ];
      }

      return [
        {
          rules: '',
          name: 'name',
          label: this.$t('name'),
          component: 'BaseInput',
          placeholder: this.$t('name'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
        },
      ];
    },
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
  },
  watch: {
    currentActiveLocation() {
        this.showMaintenance = false;
        this.loading = true;
        this.noResultsFound = false;
        setTimeout(() => {
          this.selectedFilters.name = '';
          this.stations = {
            data: [],
            totalItemCount: 0,
            count: 0,
            pageCount: 0,
            currentPage: 1,
          };
          this.loading = false;
          this.showMaintenance = true;
        }, 1200);
    },
  },
  methods: {

    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.filters.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getLocations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.filters.locations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onCompanyChanged(value) {
      this.selectedFilters.location = [];
      this.selectedFilters.company = [];
      this.filters.locations = [];
      if (value) {
        this.selectedFilters.company = [value];
        this.getLocations(this.selectedFilters.company[0].value);
      }
    },
    onLocationChanged(value) {
      if (!value) return (this.selectedFilters.location = []);

      this.selectedFilters.location = [value];
    },
    async getStations(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        CompanyId:
          this.selectedFilters.company.length > 0
            ? this.selectedFilters.company[0].value
            : null,
        LocationConfigurationId: this.isMultiOffice
          ? Number(this.currentActiveLocation.locationConfigurationId)
          : this.selectedFilters.location.length > 0 ? this.selectedFilters.location[0].id : null,
        Name: this.selectedFilters.name || null,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getStations(payload)
        .then(({ data }) => {
          this.stations = data;
          this.noResultsFound = !this.stations.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    openDeleteModal(station) {
      this.showDeleteModal = true;
      this.selectedStation = station;
    },
    onDelete() {
      _deleteStation(this.selectedStation.id)
        .then(async () => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          await this.getStations();
          this.selectedStation = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
