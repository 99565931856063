var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-4 listview card border-light"},[_c('div',{staticClass:"row"},[(!_vm.isMultiOffice)?_c('div',{staticClass:"col-12 col-sm-6 col-md-3"},[_c('div',{staticClass:"cell"},[_c('label',[_vm._v(_vm._s(_vm.$t('company'))+": ")]),_c('span',[_vm._v(_vm._s(_vm.value.companyName || '-'))])]),_c('div',{staticClass:"cell"},[_c('label',[_vm._v(_vm._s(_vm.$t('localization'))+": ")]),_c('span',[_vm._v(_vm._s(_vm.value.locationConfigurationName || '-'))])])]):_vm._e(),_c('div',{staticClass:"col-12",class:{
        'col-sm-6 col-md-3': !_vm.isMultiOffice,
        'col-sm-4': _vm.isMultiOffice,
      }},[_c('div',{staticClass:"cell"},[_c('label',[_vm._v(_vm._s(_vm.$t('stationName'))+" ESP: ")]),_c('span',[_vm._v(_vm._s(_vm.value.name || '-'))])]),_c('div',{staticClass:"cell"},[_c('label',[_vm._v(_vm._s(_vm.$t('stationName'))+" ENG: ")]),_c('span',[_vm._v(_vm._s(_vm.value.nameEN || '-'))])]),_c('div',{staticClass:"cell"},[_c('label',[_vm._v(_vm._s(_vm.$t('role'))+": ")]),_c('span',[_vm._v(_vm._s(_vm.value.roleName || '-'))])])]),_c('div',{staticClass:"col-12",class:{
        'col-sm-6 col-md-3': !_vm.isMultiOffice,
        'col-sm-4': _vm.isMultiOffice,
      }},[_c('div',{staticClass:"cell"},[_c('label',[_vm._v(_vm._s(_vm.$t('queue'))+": ")]),_c('span',[_vm._v(_vm._s(_vm.value.serviceQueueName || '-'))])]),_c('div',{staticClass:"cell"},[_c('label',[_vm._v(_vm._s(_vm.$t('available'))+": ")]),_c('span',[_vm._v(_vm._s(_vm.$t(_vm.value.isAvailable ? 'yes' : 'no')))])])]),_c('div',{staticClass:"col-12",class:{
        'col-sm-6 col-md-3': !_vm.isMultiOffice,
        'col-sm-4': _vm.isMultiOffice,
      }},[_c('action-button',{attrs:{"actions":_vm.actions,"text":_vm.$t('actions')}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }